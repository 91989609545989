<template>
  <div>
    <b-container class="h-100 py-4">
      <b-row class="h-100 justify-content-center align-items-center">
        <b-col md="8" lg="6" xl="5">
          <b-card class="shadow-lg border-top border-bottom-0 border-left-0 border-right-0 border-primary" body-class="p-5">
            <h1 class="h2 mb-5 font-weight-bold text-center d-flex align-items-center justify-content-center">
              <logo-component :fill="'#212529'" :height="'40px'" :width="'40px'" class="mr-3"> </logo-component>ACS Dashboard
            </h1>
            <b-alert variant="danger" :show="showAlert">{{ errorMessage }}</b-alert>
            <b-form v-on:submit.prevent="signInUser">
              <b-form-group>
                <label>E-Mail-Adresse</label>
                <b-form-input id="input-email" type="email" v-model="email" :state="states.email" aria-describedby="input-email-feedback" trim autocomplete="email" />
                <b-form-invalid-feedback id="input-email-feedback">
                  Keine gültige E-Mail-Adresse
                </b-form-invalid-feedback>
              </b-form-group>
              <div class="mb-4 form-group d-flex flex-column">
                <label>Passwort</label>
                <b-form-input type="password" v-model="password" trim />
              </div>
              <b-button variant="primary" type="submit" block :disabled="email === '' || password === ''">
                Anmelden
              </b-button>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LogoComponent from "../components/LogoComponent.vue";
import isEmail from "validator/es/lib/isEmail";
import { auth } from "../plugins/firebase"
import { signInWithEmailAndPassword, signInWithCustomToken } from "firebase/auth";

export default {
  name: "SignInView",
  components: {
    LogoComponent,
  },
  data() {
    return {
      email: location.hostname === "localhost" || location.hostname === "127.0.0.1" ? "thore.gersen@comline-shop.de" : "",
      password: location.hostname === "localhost" || location.hostname === "127.0.0.1" ? "egh7chf*apq8QNU!akm" : "",
      errorMessage: "",
      states: {
        email: null,
      },
    };
  },
  computed: {
    showAlert() {
      return this.errorMessage !== "";
    },
  },
  methods: {
    signInUser() {
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(() => this.$router.replace({ name: "HistoryView" }))
        .catch((error) => {
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          })
        });
    },
  },
  watch: {
    email(email) {
      if (email === "") {
        this.states.email = null
      } else {
        this.states.email = isEmail(email);
      }
    },
  },
  mounted(){
    let token = this.$route.params.token || 0
    signInWithCustomToken(auth, token)
    .then(()=>{
      this.$router.push({name: 'HistoryView'})
    })
    .catch(err=>{console.error(err)})
  }
};
</script>

<style scoped>
.border-top {
  border-width: 5px !important;
}
</style>
