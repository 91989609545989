<template>
  <b-modal
      :content-class="contentClass"
      :footer-class="footerClass"
      :body-class="bodyClass"
      :header-class="headerClass"
      hide-footer
      :id="id" 
      size="xl" 
    >
      <template #modal-title>
        <span v-b-tooltip.hover.bottom="'Order-ID'">{{
          item.orderId
        }}</span
        >
        <small
          v-if="item.reference"
          class="text-muted mt-2 d-block"
          v-b-tooltip.hover
          v-html="'Bestellnummer: ' + item.reference"
        ></small>
        <small
          v-if="item.referenceOrderId"
          class="text-muted mt-2 d-block"
          v-b-tooltip.hover
          v-html="'Referenzauftrag: ' + item.referenceOrderId"
        ></small>
      </template>
      <table class="table mb-0">
        <thead>
          <tr>
            <th>Produkt</th>
            <th>Status</th>
            <th>Menge</th>
            <th>Preis</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(lineItem, lineItemIndex) in item.lineItems"
            :key="`item-${lineItemIndex}`"
          >
            <td>
                {{ lineItem.productName | productNameWithoutLevel }}<br />
                <small
                  class="text-muted"
                  v-b-tooltip.hover
                  :title="'Herstellerartikelnummer'"
                  >{{ lineItem.offerId }}</small
                >
            </td>
            <td>
              <div :class="statusClass(lineItem.status)">
                <fa-icon
                  :icon="['fa-regular', statusIcon(lineItem.status)]"
                  class="mr-1"
                ></fa-icon
                >{{ lineItem.status | orderStatus }}
              </div>
            </td>
            <td>{{ lineItem.quantity }}</td>
            <td>
              <span v-if="item.type === 'Return'">-</span>{{ lineItem.price | currency }}
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>
</template>

<script>
export default {
  props: {
    id: String,
    item: Object
  },
  data() {
    return {
      contentClass: ["border-0", "shadow-lg"],
      footerClass: ["border-0 bg-light p-4"],
      bodyClass: ["p-4"],
      headerClass: ["border-0 bg-light px-4"],
    }
  },
  methods: {
    statusClass(status) {
      if (status === "1000") {
        return "text-success";
      } else if (status === "1002") {
        return "text-warning";
      } else {
        return "text-danger";
      }
    },
    statusIcon(status) {
      if (status === "1000") {
        return "fa-circle-check";
      } else if (status === "1002") {
        return "fa-circle";
      } else {
        return "fa-circle-xmark";
      }
    },
  }
}
</script>
