import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyCzc9CCIXwWhxycG0Set3or6RC3SjQBW5I",
  authDomain: "clgmbh-acs-demo.firebaseapp.com",
  projectId: "clgmbh-acs-demo",
  storageBucket: "clgmbh-acs-demo.appspot.com",
  messagingSenderId: "847135274595",
  appId: "1:847135274595:web:2abd5eb8c3208343c46a56"
};

const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
const firestore = getFirestore(firebase);
const functions = getFunctions(firebase, "europe-west3");
location.hostname === 'localhost' && (self.FIREBASE_APPCHECK_DEBUG_TOKEN = true)
const appCheck = initializeAppCheck(firebase, {
  provider: new ReCaptchaV3Provider('6LdeyzInAAAAAMMHLJu9gW4S4jlqgRSfpAdBgMfS'),
  isTokenAutoRefreshEnabled: true
});

if (location.hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
  connectFirestoreEmulator(firestore, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export { auth, firestore, functions, appCheck };
