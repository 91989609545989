<template>
  <div class="d-flex justify-content-center align-items-center bg-white my-5" v-if="isLoading">
    <b-spinner label="Spinning" variant="secondary"></b-spinner>
  </div>
  <b-card no-body v-else class="rounded-0">
    <b-card-header class="p-3 bg-light">
      <b-input-group class="w-25">
        <template #prepend>
          <b-input-group-text class="bg-white pr-0">
            <fa-icon :icon="['fa-regular', 'fa-magnifying-glass']"></fa-icon>
          </b-input-group-text>
        </template>
        <template #append>
          <b-button variant="dark" v-on:click="clearFilter()"><fa-icon :icon="['fa-regular', 'fa-xmark']"></fa-icon></b-button>
        </template>
        <b-input placeholder="Suche" class="border-left-0" v-model="filter" debounce="600"></b-input>
      </b-input-group>
    </b-card-header>
    <b-table
      :sticky-header="tableHeight"
      :items="filterItems"
      :fields="fields"
      class="mb-0"
      :filter="$store.state.customerFilter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      ref="table"
      >
      <template #cell(companyName)="data">
        {{ data.item.companyName}}
      </template>
      <template #cell(resellerId)="data">
        {{ data.item.resellerId }}<br/>
        <small>{{names?.[data.item.resellerId]}}</small>
      </template>
      <template #cell(customerId)="data">
        {{ data.item.customerId }}
      </template>
      <template #cell(cotermDate)="data">
        {{ data.item.cotermDate | date }}
      </template>
      <template #cell(status)="data">
        <fa-icon
          :icon="['fa-regular', statusIcon(data.item.status )]"
          :class="`mr-1 ${statusClass(data.item.status )}`"
        ></fa-icon>
        {{ data.item.status }}
        {{ data.item.status | accountStatus }}
      </template>
      <template #cell(creationDate)="data">
        {{ data.item.creationDate | dateTime }}
      </template>
    </b-table>
    <template #footer>
      <div class="d-flex justify-content-center">
        <div class="d-flex flex-column align-items-center">
          <small class="text-muted">{{ customers.length }} Kunden</small>
        </div>
      </div>
    </template>
  </b-card>
</template>

<script>
import { orderBy } from 'lodash-es';
import { firestore } from "../plugins/firebase"
import { collection, getDocs } from "firebase/firestore"; 

export default {
  name: "CustomersView",
  data() {
    return {
      filter: "",
      isLoading: true,
      customers: [],
      sortBy: "creationDate",
      sortDesc: true,
      names: {},
      fields: [
        {
          key: "companyName",
          label: "Customer",
          sortable: true,
        },
        {
          key: "customerId",
          label: "Customer-ID",
          sortable: true,
        },
        {
          key: "resellerId",
          label: "Reseller-ID",
          sortable: true,
        },
        {
          key: "cotermDate",
          label: "Anniversary-Date",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "creationDate",
          label: "Creation-Date",
          sortable: true,
        },
      ],
    };
  },
  mounted() {
    this.getCustomers();
  },
  computed: {
    tableHeight() {
      return window.innerHeight - 64 - 71 - 46 + "px";
    },
  },
  watch: {
    filter(filter) {
      this.$store.commit("customerFilter", filter);
    },
  },
  methods: {
    filterItems(ctx) {
      const customersFiltered = [];

      this.customers.forEach((customer) => {
        if (
          customer.customerId.toLowerCase().includes(ctx.filter.toLowerCase()) ||
          customer.companyName.toLowerCase().includes(ctx.filter.toLowerCase()) ||
          customer.creationDate.toLowerCase().includes(ctx.filter.toLowerCase()) ||
          customer.cotermDate.toLowerCase().includes(ctx.filter.toLowerCase()) ||
          customer.resellerId.toLowerCase().includes(ctx.filter.toLowerCase())
        ) {
          customersFiltered.push(customer);
        }
      });

      let direction = "asc";
      if (ctx.sortDesc) {
        direction = "desc";
      }

      return orderBy(
        customersFiltered,
        (customer) => {
          return customer[ctx.sortBy];
        },
        direction
      );
    },
    async getCustomers() {
      this.getResellerName()
      try {
        const querySnapshot = await getDocs(collection(firestore, "customers"));
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          this.customers.push({
            "customerId": data.customerId,
            "companyName": data.companyProfile.companyName,
            "creationDate": data.creationDate,
            "cotermDate": data.cotermDate,
            "resellerId": data.resellerId,
            "status": data.status,

          });
        })
        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    async getResellerName() {
      getDocs(collection(firestore, "resellers"))
      .then(snap=>{
        snap.forEach((doc) => {
          const data = doc.data();
          this.names[data.resellerId] = data.companyProfile.companyName 
        })
      })
    },
    statusClass(status) {
      if (status === "1000") {
        return "text-success";
      } else if (status === "1002") {
        return "text-warning";
      } else {
        return "text-danger";
      }
    },
    statusIcon(status) {
      if (status === "1000") {
        return "fa-circle-check";
      } else if (status === "1002") {
        return "fa-circle";
      } else {
        return "fa-circle-xmark";
      }
    },
    clearFilter() {
      this.filter = "";
    },
  },
};
</script>
