<template>
  <div
    class="d-flex justify-content-center align-items-center bg-white my-5"
    v-if="isLoading"
  >
    <b-spinner label="Spinning" variant="secondary"></b-spinner>
  </div>
  <b-card no-body v-else class="rounded-0">
    <b-card-header class="p-3 d-flex align-items-center justify-content-between bg-light">
      <b-input-group class="w-25 nowrap m-right-20">
        <template #prepend>
          <b-input-group-text class="bg-white pr-0">
            <fa-icon :icon="['fa-regular', 'fa-magnifying-glass']"></fa-icon>
          </b-input-group-text>
        </template>
        <template #append>
          <b-button variant="dark" v-on:click="clearFilter()"><fa-icon :icon="['fa-regular', 'fa-xmark']"></fa-icon></b-button>
        </template>
        <b-input placeholder="Suche" class="border-left-0" v-model="filter" debounce="600"></b-input>
      </b-input-group>
      <div class="d-flex align-items-center">
        <strong class="mr-3">Zeitraum&nbsp;von</strong>
        <b-input-group class="nowrap">
          <template #append>
            <b-button variant="dark" v-on:click="clearStartDate()"><fa-icon :icon="['fa-regular', 'fa-xmark']"></fa-icon></b-button>
          </template>
          <b-form-datepicker v-model="startDate" :min="startDateMin" :max="startDateMax" style="width:320px;"></b-form-datepicker>
        </b-input-group>
        <strong class="mx-3">bis</strong>
        <b-input-group class="nowrap m-right-20">
          <template #append>
            <b-button variant="dark" v-on:click="clearEndDate()"><fa-icon :icon="['fa-regular', 'fa-xmark']"></fa-icon></b-button>
          </template>
          <b-form-datepicker v-model="endDate" :min="endDateMin" :max="endDateMax" style="width:320px;"></b-form-datepicker>
        </b-input-group>
      </div>
      <div class="d-flex">
        <export-data-component :items="ordersOrdered"></export-data-component>
      </div>
    </b-card-header>

    <b-table
      ref="table"
      :sticky-header="tableHeight"
      :items="filterItems"
      :fields="fields"
      class="mb-0"
      :filter="$store.state.filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
    >
      <template #cell(status)="data">
        <fa-icon
          :icon="['fa-regular', statusIcon(data.item.status)]"
          :class="`mr-1 ${statusClass(data.item.status)}`"
        ></fa-icon>
        {{ data.item.status }}
        {{ data.item.status | orderStatus }}
      </template>
      <template #cell(cotermDate)="data">
        {{ data.item.cotermDate | date }}
      </template>
      <template #cell(orderId)="data">
        <button class="btn btn-link p-0" v-b-modal="'modal-' + data.item.orderId">{{ data.item.orderId }}</button>
        <order-modal :id="'modal-' + data.item.orderId" :item="data.item"></order-modal>
      </template>
      <template #cell(creationDate)="data">
        {{ data.item.creationDate.toDate() | dateTime }}
      </template>
      <template #cell(type)="data">
        <button class="btn btn-link p-0" v-on:click="filter = '#' + data.item.type">{{ data.item.type }}</button>
      </template>
      <template #cell(price)="data">
        <span v-if="data.item.status === '1008'">-</span>{{ data.item.price | currency }}
      </template>
    </b-table>
    <template #footer>
      <div class="d-flex justify-content-center">
        <div class="d-flex flex-column align-items-center">
          <h4 class="m-0">{{ sum | currency }}</h4>
          <small class="text-muted">{{ quantity }} Vorgänge</small>
        </div>
      </div>
    </template>
  </b-card>
</template>

<script>
import { orderBy } from 'lodash-es';
import moment from "moment-timezone";
import { firestore } from "../plugins/firebase"
import { collection, getDocs, query, where } from "firebase/firestore"; 
import ExportDataComponent from '../components/ExportDataComponent.vue';
import OrderModal from '../components/OrderModal.vue';

export default {
  components: { ExportDataComponent, OrderModal },
  name: "HistoryView",
  data() {
    return {
      startDateMin: "2021-07-23",
      startDateMax: moment().format("YYYY-MM-DD"),
      startDate: moment().format("YYYY-MM") + "-01",
      endDateMin: "2021-07-23",
      endDateMax: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      sum: 0,
      quantity: 0,
      filter: this.$store.state.filter,
      isLoading: true,
      orders: [],
      sortBy: "creationDate",
      sortDesc: true,
      fields: [
        {
          key: "resellerCustomernumber",
          label: "Kundennummer",
          sortable: true,
        },
        {
          key: "reseller",
          label: "Reseller",
          sortable: true,
        },
        {
          key: "resellerId",
          label: "Reseller-ID",
          sortable: true,
        },
        {
          key: "customer",
          label: "Endkunde",
          sortable: true,
        },
        {
          key: "customerId",
          label: "Customer-ID",
          sortable: true,
        },
        {
          key: "cotermDate",
          label: "Anniversary Date",
          sortable: true,
        },
        {
          key: "orderId",
          label: "Order-/Transfer-ID",
          sortable: true,
        },
        {
          key: "membershipId",
          label: "VIP-Nr.",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "price",
          label: "Summe",
          sortable: true,
        },
        {
          key: "type",
          label: "Art",
          sortable: true,
        },
        {
          key: "creationDate",
          label: "Creation Date",
          sortable: true,
        },
      ],
      ordersOrdered: [],
    };
  },
  async mounted() {
    await this.getOrders();
  },
  computed: {
    tableHeight() {
      return window.innerHeight - 64 - 71 - 75 + "px";
    },
  },
  watch: {
    filter(filter) {
      this.$store.commit("filter", filter);
    },
    async startDate() {
      await this.getOrders();
      this.$refs.table.refresh();
    },
    async endDate() {
      await this.getOrders();
      this.$refs.table.refresh();
    }
  },
  methods: {
    filterItems(ctx) {
        const ordersFiltered = [];
        this.isLoading = true
        this.sum = 0;
        this.quantity = 0;

        this.orders.forEach((order) => {
          if (
            (
              order.membershipId.toLowerCase().includes(ctx.filter.toLowerCase()) ||
              order.cotermDate.toLowerCase().includes(ctx.filter.toLowerCase()) ||
              order.resellerCustomernumber.toLowerCase().includes(ctx.filter.toLowerCase()) ||
              order.reseller.toLowerCase().includes(ctx.filter.toLowerCase()) ||
              order.customer.toLowerCase().includes(ctx.filter.toLowerCase()) ||
              order.resellerId.toLowerCase().includes(ctx.filter.toLowerCase()) ||
              order.customerId.toLowerCase().includes(ctx.filter.toLowerCase()) ||
              order.orderId.toLowerCase().includes(ctx.filter.toLowerCase()) ||
              order.status.toLowerCase().includes(ctx.filter.toLowerCase()) ||
              order.price.toString().includes(ctx.filter.toString()) ||
              order.type.toLowerCase().includes(ctx.filter.toLowerCase()) ||
              order.searchType.toLowerCase().includes(ctx.filter.toLowerCase()) ||
              order.creationDate.toDate().toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' }).toLowerCase().includes(ctx.filter.toLowerCase())
            ) 
          ) {
            if (order.status !== "1008" && order.status !== "1004") {
              this.sum += order.price
            } else if (order.status === "1008") {
              this.sum += -order.price
            }
            this.quantity ++
            ordersFiltered.push(order);
          }
        });

        let direction = "asc"
        if (ctx.sortDesc) {
          direction = "desc"
        }

        const ordersOrdered = orderBy(ordersFiltered, (order) => {
          return order[ctx.sortBy]
        }, direction);

        this.ordersOrdered = ordersOrdered;
        this.isLoading = false
        return ordersOrdered;
    },
    async getOrders() {
      try {
        this.isLoading = true
        this.orders = []
        const colRef = collection(firestore, "dashboardOrders");
        const q = query(colRef, where("creationDate", ">=", new Date(`${moment(this.startDate).format("YYYY/MM/DD")} 00:00:00`)), where("creationDate", "<=", new Date(`${moment(this.endDate).format("YYYY/MM/DD")} 23:59:59`)));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          data["searchType"] = "#" + data.type;
          this.orders.push(data);
        });

        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    statusClass(status) {
      if (status === "1000") {
        return "text-success";
      } else if (status === "1002") {
        return "text-warning";
      } else {
        return "text-danger";
      }
    },
    statusIcon(status) {
      if (status === "1000") {
        return "fa-circle-check";
      } else if (status === "1002") {
        return "fa-circle";
      } else {
        return "fa-circle-xmark";
      }
    },
    clearFilter() {
      this.filter = "";
    },
    clearStartDate() {
      this.startDate = "2021-07-23";
    },
    clearEndDate() {
      this.endDate = moment().format("YYYY-MM-DD");
    },
  }
};
</script>

<style scoped>
.nowrap {
  flex-wrap: nowrap;
}
.m-right-20 {
  margin-right: 20px;
} 
</style>