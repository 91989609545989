<template>
  <div class="d-flex justify-content-center align-items-center bg-white" style="height: calc(100% - 56px);"
    v-if="isLoading">
    <b-spinner label="Spinning" variant="secondary"></b-spinner>
  </div>
  <div v-else>
    <navigation-component></navigation-component>
    <router-view></router-view>
  </div>
</template>

<script>
import NavigationComponent from "../components/NavigationComponent.vue";
import { auth, firestore } from "../plugins/firebase"
import { signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

export default {
  name: "SignedInView",
  components: { NavigationComponent },
  data() {
    return {
      isLoading: true,
    };
  },
  async created() {
    const currentUser = await auth.currentUser
    const getIdTokenResult = currentUser ? await currentUser.getIdTokenResult() : false
    const isAdmin = getIdTokenResult ? getIdTokenResult.claims.admin : false

    try {
      if (isAdmin) { this.isLoading = false; }

      const docRef = doc(firestore, "dashboardSettings", "summaryQuarterSettings");
      const docSnap = await getDoc(docRef);

      if (docSnap.data().summaryQuarterSettings !== undefined) {
        this.$store.commit("storeResellerData", {
          summaryQuarterSettings: docSnap.data().summaryQuarterSettings,
        });
      }

    } catch (error) {
      console.error(error);
      signOut(auth)
        .then(() => this.$router.replace("/"))
        .catch((error) => console.error(error.message));
    }
  },
};
</script>
