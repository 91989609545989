import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCircle,
  faCheck,
  faArrowRightFromBracket,
  faCircleCheck,
  faCircleXmark,
  faTriangleExclamation,
  faMagnifyingGlass,
  faXmark,
  faGear,
  faBars,
  faEllipsisVertical,
  faRightToBracket
} from "@fortawesome/pro-regular-svg-icons";
library.add(
  faArrowRightFromBracket,
  faCircle,
  faCircleCheck,
  faCircleXmark,
  faTriangleExclamation,
  faCheck,
  faMagnifyingGlass,
  faXmark,
  faGear,
  faBars,
  faEllipsisVertical,
  faRightToBracket
);
Vue.component("fa-icon", FontAwesomeIcon);
