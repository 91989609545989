<template>
  <b-button variant="dark" v-on:click="exportData()">Daten exportieren</b-button>
</template>

<script>
import { utils, writeFileXLSX } from 'xlsx';

export default {
  name: "ExportDataComponent",
  props: {
    items: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  methods: {
    exportData() {
      const ItemsConverted = []
      this.items.forEach(element => {
        const newElement = element
        newElement.creationDate = element.creationDate.toDate().toLocaleString('de-DE')
        ItemsConverted.push(newElement)
      });
      const worksheet = utils.json_to_sheet(ItemsConverted);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Daten");
      writeFileXLSX(workbook, "acs-dashboard-export.xlsx");
    }
  }
}
</script>