<template>
  <b-container v-if="adobeResellerData && dbResellerData" class="py-4">
    <b-form v-on:submit.prevent="updateResellerAccount">
      <b-row>
        <b-col class="d-flex flex-column justify-content-center">
          <label class="mb-0">Kundennummer</label>
          <small class="text-muted">Nicht änderbar.</small>
        </b-col>
        <b-col>
          <b-form-input readonly :value="dbResellerData.customernumber" v-if="!isLoading"></b-form-input>
          <b-skeleton type="input" v-else></b-skeleton>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col class="d-flex flex-column justify-content-center">
          <label class="mb-0">Reseller-ID</label>
          <small class="text-muted">Nicht änderbar.</small>
        </b-col>
        <b-col>
          <b-form-input readonly :value="adobeResellerData.resellerId" v-if="!isLoading"></b-form-input>
          <b-skeleton type="input" v-else></b-skeleton>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col class="d-flex flex-column justify-content-center">
          <label class="mb-0">Firma</label>
          <small class="text-muted">Nicht änderbar.</small>
        </b-col>
        <b-col>
          <b-form-input readonly :value="adobeResellerData.companyProfile.companyName" v-if="!isLoading"></b-form-input>
          <b-skeleton type="input" v-else></b-skeleton>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col class="d-flex align-items-center">
          <label class="mb-0">Adresszeile 1</label>
        </b-col>
        <b-col>
          <b-form-input v-model="adobeResellerData.companyProfile.address.addressLine1" v-if="!isLoading"></b-form-input>
          <b-skeleton type="input" v-else></b-skeleton>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="d-flex align-items-center">
          <label class="mb-0">Adresszeile 2</label>
        </b-col>
        <b-col>
          <b-form-input v-model="adobeResellerData.companyProfile.address.addressLine2" v-if="!isLoading"></b-form-input>
          <b-skeleton type="input" v-else></b-skeleton>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col class="d-flex align-items-center">
          <label class="mb-0">PLZ</label>
        </b-col>
        <b-col>
          <b-form-input id="input-postalcode" type="text" v-model="adobeResellerData.companyProfile.address.postalCode"
            :state="states.postalCode" v-if="!isLoading" aria-describedby="input-postalcode-feedback" trim required />
          <b-skeleton type="input" v-else></b-skeleton>
          <b-form-invalid-feedback id="input-postalcode-feedback">
            Keine gültige Postleitzahl
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col class="d-flex align-items-center">
          <label class="mb-0">Ort</label>
        </b-col>
        <b-col>
          <b-form-input v-model="adobeResellerData.companyProfile.address.city" v-if="!isLoading"></b-form-input>
          <b-skeleton type="input" v-else></b-skeleton>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col class="d-flex flex-column justify-content-center">
          <label class="mb-0">Land</label>
          <small class="text-muted">Nicht änderbar</small>
        </b-col>
        <b-col>
          <b-form-input v-model="adobeResellerData.companyProfile.address.country" v-if="!isLoading"
            disabled></b-form-input>
          <b-skeleton type="input" v-else></b-skeleton>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col class="d-flex align-items-center">
          <label class="mb-0">Telefonnummer</label>
        </b-col>
        <b-col>
          <b-form-input v-model="adobeResellerData.companyProfile.address.phoneNumber" v-if="!isLoading"></b-form-input>
          <b-skeleton type="input" v-else></b-skeleton>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col class="d-flex align-items-center">
          <label class="mb-0">Sprache</label>
        </b-col>
        <b-col>
          <preferred-language-dropdown-component :preferredLanguage="adobeResellerData.companyProfile.preferredLanguage"
            v-on:languageSelected="languageSelected" v-if="!isLoading"></preferred-language-dropdown-component>
          <b-skeleton type="input" v-else></b-skeleton>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col>
          <label class="mb-0">Marktsegmente</label>
        </b-col>
        <b-col>
          <b-form-checkbox 
            v-model="checkedMarketSegments.COM" disabled switch>
            Commercial
          </b-form-checkbox>
          <b-form-checkbox 
            v-model="checkedMarketSegments.EDU" switch>
            Education
          </b-form-checkbox>
          <b-form-checkbox 
            v-model="checkedMarketSegments.GOV" switch>
            Government
          </b-form-checkbox>
        </b-col>
      </b-row>
      <hr />

      <submit-button-component :state="submitButtonState" label="Speichern" :block="false"
        class="my-2"></submit-button-component>
    </b-form>
  </b-container>
</template>

<script>
import { firestore, functions } from "../plugins/firebase";
import { httpsCallable } from 'firebase/functions';
import { collection, getDocs, query, where } from "firebase/firestore";
import PreferredLanguageDropdownComponent from "../components/PreferredLanguageDropdownComponent.vue";
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";

export default {
  name: "ResellerView",
  components: {
    PreferredLanguageDropdownComponent,
    SubmitButtonComponent,
  },
  props: {
    resellerId: {
      type: String,
      required: true,
    },
  },
  computed: {
    submitButtonState() {
      if (
        !this.states.postalCode ||
        this.adobeResellerData.companyProfile.address.addressLine1 === "" ||
        this.adobeResellerData.companyProfile.address.city === ""
      ) {
        return "missingFields";
      }

      if (this.isWorking === true) {
        return "working";
      }

      return "";
    },
  },
  data() {
    return {
      adobeResellerData: null,
      dbResellerData: null,
      isLoading: true,
      isWorking: false,
      states: {
        postalCode: null
      },
      checkedMarketSegments: {
        COM: false,
        EDU: false,
        GOV: false,
      },
    };
  },
  mounted() {
    this.getReseller();
  },
  methods: {
    async getReseller() {
      const getResellerAccount = httpsCallable(functions, "getResellerAccount");
      await getResellerAccount({ resellerId: this.resellerId })
        .then((result) => {
          this.adobeResellerData = result.data;

          result.data.companyProfile.marketSegments.forEach((marketSegment) => {
            this.checkedMarketSegments[marketSegment] = true;
          });
        })
        .catch((error) => {
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          })
        });

      const colRef = collection(firestore, "resellers");
      const q = query(colRef, where("resellerId", "==", this.resellerId));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        this.dbResellerData = doc.data();
      });

      this.isLoading = false;
    },
    languageSelected(preferredLanguage) {
      this.adobeResellerData.companyProfile.preferredLanguage = preferredLanguage;
    },
    updateResellerAccount() {
      this.isWorking = true;
      const updateResellerAccount = httpsCallable(functions, "updateResellerAccount");
      updateResellerAccount({
        resellerId: this.adobeResellerData.resellerId,
        customerNumber: this.dbResellerData.customernumber,
        logo: this.dbResellerData.logo,
        profile: {
          companyProfile: this.adobeResellerData.companyProfile,
        },
      })
        .then(() => {
          this.$store.dispatch("alertSuccess", {
            message: "Das Profil wurde erfolgreich aktualisiert.",
            show: true,
            noFooter: false,
          })
        })
        .catch((error) => {
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          })
        })
        .finally(() => {
          this.isWorking = false;
        });
    },
  },
  watch: {
    "adobeResellerData.companyProfile.address.postalCode": function (postalCode) {
      const postalCodeRegex = this.$store.state.postalCodeRegex;

      if (postalCode === "") {
        this.states.postalCode = null;
      } else {
        if (postalCode.match(postalCodeRegex[this.adobeResellerData.companyProfile.address.country])) {
          this.states.postalCode = true;
        } else {
          this.states.postalCode = false;
        }
      }
    },
    checkedMarketSegments: {
      handler: function (checkedMarketSegments) {
        this.adobeResellerData.companyProfile.marketSegments = [];

        Object.keys(checkedMarketSegments).forEach((key) => {
          if (checkedMarketSegments[key]) {
            this.adobeResellerData.companyProfile.marketSegments.push(key);
          }
        });
      },
      deep: true,
    },
  }
};
</script>