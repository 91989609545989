<template>
  <div class="rounded-0 p-3">
    <b-form v-on:submit.prevent="saveProducts">
      <submit-button-component :state="submitButtonState" label="Produktimport starten" :block="false" variant="dark"></submit-button-component>
  </b-form>
  </div>
</template>

<script>
import { functions } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";

export default {
  name: "ProductImportView",
  components: {
    SubmitButtonComponent,
  },
  computed: {
    submitButtonState() {
      if (this.isWorking === true) {
        return "working";
      }

      return "";
    },
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Bezeichnung",
        },
        {
          key: "sku",
          label: "ComLine Artikelnummer",
        },
        {
          key: "offerId",
          label: "Adobe Offer-ID",
        },
      ],
      products: [
        /* {
          name: "poj",
          sku: "wefwef",
          offerId: "wefwefwef",
        } */
      ],
      isWorking: false,
    };
  },
  methods: {
    async saveProducts() {
      this.isWorking = true;

      const saveProductsDashboardTrigger = httpsCallable(functions, "saveProductsDashboardTrigger", {timeout: 540000}); // timeout ist in millisekunden
      await saveProductsDashboardTrigger()
        .then(() => {
          this.$store.dispatch("alertSuccess", {
            message: "Fertig",
            show: true,
            noFooter: false,
          })
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          })
        });

      this.isWorking = false;
    },
  },
};
</script>
